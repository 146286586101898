module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/codebuild/output/src561247128/src/kushki-docs/node_modules/gatsby-remark-images-contentful","id":"2a82ab2c-128d-533e-98bd-accb50679683","name":"gatsby-remark-images-contentful","version":"2.10.0","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/codebuild/output/src561247128/src/kushki-docs/node_modules/gatsby-remark-images-medium-zoom","id":"9a661529-a37d-52cd-b9a8-f94d932ba2e7","name":"gatsby-remark-images-medium-zoom","version":"1.7.0","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"gatsbyRemarkPlugins":["gatsby-remark-smartypants",{"resolve":"gatsby-remark-autolink-headers","options":{"isIconAfterHeader":false}},{"resolve":"gatsby-remark-images-contentful","options":{"backgroundColor":"transparent","linkImagesToOriginal":false,"maxWidth":1200,"showCaptions":true,"withWebp":true}},{"resolve":"gatsby-remark-images-medium-zoom","options":{}}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/codebuild/output/src561247128/src/kushki-docs"},
    },{
      plugin: require('../node_modules/gatsby-remark-images-medium-zoom/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#023365","display":"standalone","icon":"src/images/favicon.png","name":"Kushki Docs","short_name":"Kushki Docs","start_url":"/","theme_color":"#023365","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d79afd180c02246935a8aefeab4e7bbe"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"pluginConfig":{"exclude":["/404"],"head":true},"trackingIds":["G-20K48NTSR6","UA-128563279-3"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intercom-spa/gatsby-browser.js'),
      options: {"plugins":[],"app_id":"c9kpvatv","delay_timeout":5000,"include_in_development":true},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    }]
